<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <!-- title -->
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">综合看板</h5>
              </a-col>
              <a-col :span="24">
                <div class="display-content" v-html="content"></div>
              </a-col>
            </a-row>
          </template>
          <a-tabs type="card" >
            <a-tab-pane key="1" tab="信息流看板">
              <sumFlow></sumFlow>
            </a-tab-pane>
            <a-tab-pane key="2" tab="新关注看板">
              <newFollow></newFollow>
            </a-tab-pane>
          </a-tabs>


        </a-card>
      </a-col>
    </a-row>

  </div>
</template>

<script>

import { getSummaryList, getSumDetailsList, getClassifyTree, monitorInit } from "@/api/service.js";
import { getHomeConfig } from "@/api/config.js";
import sumFlow from './component/sumFlow.vue';
import newFollow from './component/newFollow.vue';

export default ({
  components: {
    sumFlow,
    newFollow
  },
  data() {
    return {
      content: ''
    }
  },
  async mounted() {

    this.getHomeConfig();

  },
  methods: {
    getHomeConfig() {
      getHomeConfig({ type: 'sum' }).then(res => {
        let data = res.data.data;
        if (data.length > 0) {
          this.content = data[0].content;
        }
      })
    },
  }
})

</script>

<style lang="scss" scoped>
::v-deep .display-content {
  p {
    margin-bottom: 0;
  }
}
</style>