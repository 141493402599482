<template>
  <div class="new-follow-wrap">
    <a-radio-group @change="tagChange" v-model="tagSelect" :default-value="tagSelect" size="small"
      style="padding-left: 24px;padding-bottom: 20px;">
      <a-radio-button :value="item" v-for="(item, index) in tag" :key="index">
        {{ item }}
      </a-radio-button>
    </a-radio-group>
    <div class="list">
      <div class="item" v-for="(item, index) in newFollowList" :key="index">
        <div class="header-wrap">
          <div class="left">
            <img :src="monitorUserObj[item.monitorId].img" alt="">
            <a :href="'https://x.com/' + item.monitorId" target="_blank">{{ monitorUserObj[item.monitorId].name }}</a>
            <span>关注了</span>
          </div>
          <div class="right">
            {{ item.followTime }}
          </div>
        </div>
        <div class="new-user">
          <img :src="item.followImg" alt="" srcset="">

          <div class="right">
            <a :href="'https://x.com/' + item.followId" target="_blank">{{ item.followName }}</a>
            <div class="tw_id">@{{ item.followId }}</div>
            <span>粉丝量：{{ item.followCount }}</span>
            <div class="introduction">{{ item.followIntroduction }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

const formattedDate = (dateString, format) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  if (format == 'yyyy-mm-dd h:m:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else if (format == 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`;
  } else if (format == 'yyyy-mm-dd h') {
    return `${year}-${month}-${day} ${hours}`;
  } else if (format == 'h:m') {
    return `${hours}:${minutes}`;
  }
}
import { getNewFollowList, getMonitorUser } from "@/api/service.js";

export default {
  name: 'newFollow',
  data() {
    return {
      tag: [],
      tagSelect: "",
      monitorUser: [],
      monitorUserObj: {},
      newFollowList: []
    };
  },
  async mounted() {

    // 获取所有监控用户
    this.getMonitorUser();
    this.getLastFiveDays();
  },
  methods: {
    getMonitorUser() {
      getMonitorUser().then(res => {
        let data = res.data.data;
        data.forEach(item => {
          this.monitorUserObj[item.twId] = item;
        });
        // 获取关注数据
        this.getNewFollowList();
      })
    },
    getNewFollowList() {
      getNewFollowList({
        followTime: this.tagSelect
      }).then(res => {
        let data = res.data.data;
        data.forEach(item => {
          item.followTime = formattedDate(item.followTime, 'h:m')
        });
        this.newFollowList = data;
      })
    },
    tagChange(e) {
      this.tagSelect = e.target.value;
      this.getNewFollowList();
    },
    getLastFiveDays() {
      const dates = [];
      const today = new Date();
      for (let i = 0; i < 5; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i); // 设置为今天往前的日期
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要加 1
        const day = date.getDate().toString().padStart(2, '0'); // 补齐为两位数
        dates.push(`${year}-${month}-${day}`);
      }
      this.tag = dates;
      this.tagSelect = this.tag[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  justify-content: space-between;

  .item {
    width: calc(50% - 10px);
    border-radius: 10px;
    box-shadow: 0px 0px 17px 4px rgba(114, 114, 131, 0.05);
    padding: 20px;
    margin-bottom: 20px;
    .header-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #F5F5F5;

      .left {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        a {
          font-weight: 700;
        }

        span {
          font-weight: 600;
          padding-left: 10px;
        }
      }

      .right {
        width: 59px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(63, 140, 255, 0.12);
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #3F8CFF;
      }
    }

    .new-user {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 10px;

      img {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .right {
        flex: 1;
        display: flex;
        flex-flow: column;
        a {
            font-weight: 700;
            font-size: 16px;
          }
        .tw_id {
          color: rgb(83, 100, 113);
        }
        a,span,.tw_id{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>